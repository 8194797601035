import React from 'react';
import { connect } from 'dva';
import { Modal, Button, Radio, Checkbox, Row, Col } from 'antd';

import intl from 'react-intl-universal';
import _ from 'lodash'

@connect(({ guidance, loading, request,user }) => ({
  request,
  guidance,
  loading: loading.models.guidance,
  paymentPlan: user.plan,
  newUserInfo:user.newUserInfo
}))
export default class Pricing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shopvalue: [],
      stores: [],
      buttonDisabled: true,
      shop: false,
      isDisabled: true,
      accounts:0,
      selectChildAccount:[this.props.newUserInfo?.user_id]
    };
  }

  componentDidMount() {
    if(this.props.paymentPlan.type === 'advanced'){
      this.setState({
        accounts:5
      })
    }
  }

  onChoiceAccount = e => {
    const { childAccount } = this.props;

    const { accountQuantity } = this.props;

    this.setState({
      selectChildAccount:e
    })

    if (e.length == accountQuantity) {
      childAccount.map(item => {
        item.disabled = true;
      });
      e.map(it => {
        childAccount.map(item => {
          if (it == item.user_id) {
            item.disabled = false;
          }
        });
      });
    } else {
      childAccount.map(item => {
        item.disabled = false;
      });
    }

    this.setState({
      shopvalue: e,
      isDisabled: !e.length
    });
  };

  handleConfirm = (value)=>{
    return ()=>{
      const { childAccount } = this.props;
      let status_1 = []
      let status_3 = []
     childAccount.forEach(item=>{
      //  if(value.includes(item.user_id) && item.status === 1){
      //     status_1.push(item.user_id)
      //  }else if(value.includes(item.user_id) && item.status === 3){
      //   status_3.push(item.user_id)
      //  }
       if (!value.includes(item.user_id)) {
         return
       }
       switch (item.status) {
         case 1:
           status_1.push(item.user_id)
           break;
         case 3:
           status_3.push(item.user_id)
           break;
         case 5:
           status_3.push(item.user_id)
           break;
         case 6:
           status_3.push(item.user_id)
          default:
       }
     })
     this.props.saveAccounts(status_1,status_3)
    }
  }

  render() {
    const {
      value,
      stores,
      buttonDisabled,
      shop,
      shopvalue,
      isDisabled,
      storeId,
      selectChildAccount
    } = this.state;
    const { accountQuantity,childAccount } = this.props;

    return (
      <Modal
        centered
        closable={false}
        visible
        footer={[
          <Button
            disabled={isDisabled}
            onClick={this.handleConfirm(shopvalue)}
            type="primary"
          >
            {intl.get('pricing.Confirm')}
          </Button>
        ]}
        
      >
        <h3 style={{marginTop:'20px'}}>{`${intl.get('pricing.select_account')} ${this.props.accountQuantity} ${intl.get('pricing.select_account_2')}`}</h3>
        {childAccount ? (
          <div>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={this.onChoiceAccount}
              value={selectChildAccount}
            >
              {childAccount?.map((item,index) => {
               if(index !== 0){
                return (
                  <Row style={{ margin: 5 }}>
                    <Col>
                      <Checkbox
                        disabled={item.user_id == this.props.newUserInfo?.user_id ? true : item.disabled}
                        value={item.user_id}
                      >
                        {item.email}
                      </Checkbox>
                      
                    </Col>
                  </Row>
                );
               }
              })}
            </Checkbox.Group>
          </div>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}
