import React from 'react';
import { connect } from 'dva';
import { Modal, Button, Radio, Checkbox, Row, Col } from 'antd';

import intl from 'react-intl-universal';

@connect(({ guidance, loading, request }) => ({
  request,
  guidance,
  loading: loading.models.guidance
}))
export default class Pricing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shopvalue: [],
      woovalue: [],
      stores: [],
      woo_stores: [],
      buttonDisabled: true,

      shop: false,
      woo: false,

      isDisabled: true
    };
  }

  componentDidMount() {
    const { stores, woo_stores } = JSON.parse(localStorage.getItem('store'));
    this.props.dispatch({
      type: 'guidance/getPlan',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            stores &&
              stores.map(item => {
                if (item.id == d.data.default_store_id) {
                  item.labele = true;
                } else {
                  item.labele = false;
                }
              });
          }
        }
      }
    });
    this.setState({
      stores,
      woo_stores
    });
  }

  onChoiceShop = e => {
    const { stores } = this.state;

    const { quantity } = this.props;

    if (e.length > 0) {
      this.setState({
        isDisabled: false
      });
    } else {
      this.setState({
        isDisabled: true
      });
    }

    if (e.length == quantity) {
      stores.map(item => {
        item.disabled = true;
      });
      e.map(it => {
        stores.map(item => {
          if (it == item.id) {
            item.disabled = false;
          }
        });
      });
    } else {
      stores.map(item => {
        item.disabled = false;
      });
    }

    if (e.length > 0) {
      this.setState({
        shop: false,
        woo: true
      });
    } else {
      this.setState({
        shop: false,
        woo: false
      });
    }

    this.setState({
      shopvalue: e,
      buttonDisabled: false
    });
  };

  onChoiceWoo = e => {
    if (e.length > 0) {
      this.setState({
        isDisabled: false
      });
    } else {
      this.setState({
        isDisabled: true
      });
    }

    if (e.length > 0) {
      this.setState({
        shop: true,
        woo: false
      });
    } else {
      this.setState({
        shop: false,
        woo: false
      });
    }

    this.setState({
      woovalue: e,
      buttonDisabled: false
    });
  };

  render() {
    const {
      value,
      stores,
      buttonDisabled,
      woo_stores,
      shop,
      woo,
      shopvalue,
      woovalue,
      isDisabled,
      storeId
    } = this.state;
    const { quantity } = this.props;

    return (
      <Modal
        centered
        closable={false}
        visible
        footer={[
          <Button
            disabled={isDisabled}
            onClick={() => this.props.dispatchs(shopvalue, woovalue)}
            type="primary"
          >
            {intl.get('pricing.Confirm')}
          </Button>
        ]}
      >
        <h3>{intl.get('pricing.selectShop')}</h3>
        <div style={{ marginBottom: 50 }}>
          {intl.getHTML('pricing.keepShop', { quantity })}
        </div>
        <h3>{intl.get('pricing.PlatformAndStores')}</h3>
        {stores ? (
          <div>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              {intl.get('pricing.ShopifyPlatform')}
            </div>
            <Checkbox.Group
              style={{ width: '100%' }}
              disabled={shop}
              onChange={this.onChoiceShop}
            >
              {stores.map(item => {
                return (
                  <Row style={{ margin: 5 }}>
                    <Col>
                      {/* item.labele ||  */}
                      <Checkbox
                        disabled={item.disabled || item.status == 2}
                        value={item.id}
                      >
                        {item.domain}
                      </Checkbox>
                      {item.labele ? (
                        <span style={{ marginLeft: 20, color: '#228B22' }}>
                          {intl.get('pricing.billing')}
                        </span>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Checkbox.Group>
          </div>
        ) : (
          ''
        )}

        {woo_stores ? (
          <div>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              {intl.get('pricing.Woocommerceplatform')}
            </div>
            <Checkbox.Group
              style={{ width: '100%' }}
              disabled={woo}
              onChange={this.onChoiceWoo}
            >
              {woo_stores.map(item => {
                return (
                  <Row style={{ margin: 5 }}>
                    <Col>
                      <Checkbox disabled={item.disabled} value={item.store_id}>
                        {item.domain}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </Checkbox.Group>
          </div>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}
