import React, { Fragment } from 'react';
import { connect } from 'dva';
import {
  Card,
  Button,
  Radio,
  Alert,
  Icon,
  Tooltip,
  Spin,
  message,
  Modal
} from 'antd';
import intl from 'react-intl-universal';
import { routerRedux } from 'dva/router';

import Demotion from './demotion';
import DemotionAccount from './demotionaccount';
import styles from './select.less';
import RefreshMask from 'components/RefreshMask';
import WhyDisconnect from 'components/WhyDisconnect/WhyDisconnect';
import DisconnectCallback from 'components/WhyDisconnect/DisconnectCallback';
import { goToWoo } from '../../common/sysconfig';
import ResetPasswordModal from 'routes/User/ResetPasswordModal';
import FixedHeaderLayout from 'layouts/FixedHeaderLayout';
import defaultStore from '../../assets/svg/pricing.svg';
import { getLanguage } from '../../utils/utils';
import { Put, Get } from 'utils/request';
import OblerloGuide from '../../components/OblerloGuide'
import { GuildeAction, LinkStoreAction } from 'actions'
import { getOberloGuidePop } from '../../services/oberloGuilde'
import ProductLimit from '../../components/ProductLimit'
import { CommonDataCollect } from 'utils/utils';
import { CAM002 } from 'components/Cam';
import TmallStartImport from '../../components/OnlineNotice/TmallStartImport'
@connect(({ guidance, loading, login, user, dropshippersetting }) => ({
  guidance,
  userInfo: login.userInfo,
  paymentPlan: user.plan,
  loading: loading.models.guidance,
  childAccount: dropshippersetting.childAccount,
  newUserInfo:user.newUserInfo
}))
export default class Pricing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setMealName: '',
      stores: [],
      status: '',
      woo_stores: '',
      value: '',
      choiceLabel: '',
      visible: false,
      buttonDisabled: true,
      type: '',
      quantity: 0,//店铺数量
      accountquantity:0,//套餐限制的子账号数量
      multipleChoice: false,
      activate: null,
      nonactivated: null,
      mistakeText: false,
      mistakeDomain: '',
      showRefreshMask: false,
      pageTable: true,
      disconnectShopifyStoreId: undefined, // 卸载shopify店铺id
      showDisconnectCallback: false, // 卸载shopify店铺成功后的弹窗,
      childAccount:[],
      charge_id:0,
      isModalVisiable:false
    };
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'guidance/getInfo',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            const { stores, woo_stores, status } = d.data;
            const planStoreLimitMap = {
              1: 3,
              2: 10,
              3: 25,
              4: 5,
              5: 50,
              6: Infinity,
              7: 10,
              8: 5,
              10: 3
            };
            const planAccountLimitMap={
              1:0,
              2:5,
              3:Infinity,
              4:0,
              5:Infinity,
              6:Infinity,
              7:5,
              8:0,
              10:0
            }
            if (
              stores &&
              stores.length == 0 &&
              `${status}`.length == '8' &&
              `${status}`.substring(3, 4) == 0
            ) {
              window.location.href = '/app/bind/platform';
            }
            const { type = {}, default_store_id = {}, old_user = false } =
              JSON.parse(localStorage.getItem('payment')) || {};
            const payment = JSON.parse(localStorage.getItem('payment')) || {};
            this.props.dispatch({
              type: 'guidance/storeList',
              payload: {
                callback: d => {
                  if (d.code === 2000) {
                    const activate = [];
                    const nonactivated = [];

                    d.data.map(item => {
                      item.store_name += '.myshopify.com';
                      if (item.hidden) {
                        nonactivated.push(item);
                      } else {
                        activate.push(item);
                      }
                    });

                    let amount = 0;
                    activate.map(item => {
                      if (item.status == '1') {
                        amount += item.status;
                      }
                    });

                    // 用户活跃店铺不少于套餐限制数时 不能从inactive店铺列表激活店铺
                    const inactiveDisabled = amount >= planStoreLimitMap[type];

                    nonactivated.forEach(item => {
                      item.disabled = inactiveDisabled;
                    });

                    // if (type == '1' && amount < 3) {
                    //   nonactivated.map(item => {
                    //     item.disabled = false;
                    //   });
                    // } else if (type == '2' && amount < 10) {
                    //   nonactivated.map(item => {
                    //     item.disabled = false;
                    //   });
                    // } else if (type == '3' && amount < 25) {
                    //   nonactivated.map(item => {
                    //     item.disabled = false;
                    //   });
                    // } else if (type == '4' && amount < 5) {
                    //   nonactivated.map(item => {
                    //     item.disabled = false;
                    //   });
                    // } else {
                    //   nonactivated.map(item => {
                    //     item.disabled = true;
                    //   });
                    // }

                    this.setState({
                      activate,
                      nonactivated
                    });
                  }
                }
              }
            });

            let amount = 0;

            if (stores) {
              const hasActiveStore = stores.some(
                store => store.id === payment.default_store_id
              );
              stores.map(item => {
                if (item.id == default_store_id) {
                  this.props.dispatch({
                    type: 'guidance/shopifyStatus',
                    payload: {
                      data: {
                        store_id: item.id
                      },
                      callback: data => {
                        if (
                          data.code === 2000 &&
                          !data.data.is_available
                        ) {
                          this.setState({
                            mistakeText: true,
                            mistakeDomain: item.domain
                          });
                          item.status = 2;
                        } else {
                          item.status = 1;
                        }
                      }
                    }
                  });
                  item.label = true;
                }
                if (item.status == '1') {
                  amount += item.status;
                }
                if (item.id === payment.default_store_id && item.status !== 1) {
                  this.setState({
                    mistakeText: true,
                    mistakeDomain: item.domain
                  });
                }
              });

              if (!hasActiveStore) {
                this.setState({
                  mistakeText: true
                });
              }
            }
            // active店铺 大于当前套餐限制 让用户选择保留的店铺
            if (amount > planStoreLimitMap[type] && !old_user) {
              if(localStorage.getItem('noMigra') !== 'false'){
                this.setState({
                  multipleChoice: true,
                  quantity: planStoreLimitMap[type]
                });
               } else {
                const threeDays = parseInt(localStorage.getItem('EnterNewHomeTime')) + 259200
                const nowTime = parseInt(new Date().getTime() / 1000)
                let planKey = JSON.parse(localStorage.getItem('store'))?.user_id;
                if(localStorage.getItem(`${planKey}-reducePlan`) == 'true' || threeDays < nowTime){
                  this.setState({
                    multipleChoice: true,
                    quantity: planStoreLimitMap[type]
                  });
                }
               }
            }
            // if (type == '1' && amount > 3 && !old_user) {
            //   this.setState({
            //     multipleChoice: true,
            //     quantity: 3
            //   });
            // } else if (type == '2' && amount > 10 && !old_user) {
            //   this.setState({
            //     multipleChoice: true,
            //     quantity: 10
            //   });
            // } else if (type == '3' && amount > 25 && !old_user) {
            //   this.setState({
            //     multipleChoice: true,
            //     quantity: 25
            //   });
            // } else if (type == '4' && amount > 5 && !old_user) {
            //   this.setState({
            //     multipleChoice: true,
            //     quantity: 5
            //   });
            // }
            this.setState({
              type,
              value: default_store_id
            });
            const planIntlKeys = {
              1: 'pricing.Basic',
              2: 'pricing.Advanced',
              3: 'pricing.Pro',
              4: 'pricing.Standard',
              5: 'pricing.Business',
              6: 'pricing.Enterprise',
              7: 'pricing.Advanced',
              8: 'pricing.Standard',
              9: 'pricing.custom_pro',
              10:'pricing.custom_basic',
              11:'pricing.Custom_EnterPrise',
              12:'pricing.BasicTracking',
              13:'pricing.Basic500',
              14:'pricing.Basic1000',
              15:'pricing.CustomBasicTracking',
              16:'pricing.CustomBasic500',
              17:'pricing.CustomBasic1000',
              18: 'pricing.Basic',
              19: 'pricing.Basic',
              20: 'pricing.Basic',
            };
            this.setState({
              setMealName: intl.get(`${planIntlKeys[type]}`) || ''
            });

            this.setState({
              stores,
              woo_stores,
              status
            });

            if (!this.props.newUserInfo?.user_role) {  //只有admin账户才执行
              if(localStorage.getItem('noMigra') !== 'false'){
                this.getSubAccount(planAccountLimitMap[type])
               } else {
                //  getOberloGuidePop({ data: { name: 'isBindPlan' } }).then(res => {
                //   if (res.value) {
                //     this.getSubAccount(planAccountLimitMap[type])
                //   }
                // });
                let planKey = JSON.parse(localStorage.getItem('store'))?.user_id;
                if(localStorage.getItem(`${planKey}-reducePlan`) == 'true'){
                  this.getSubAccount(planAccountLimitMap[type])
                }
               }
            }
          }
          this.setState({
            pageTable: false
          });
        }
      }
    });
    if(this.props.paymentPlan.status === 3){
      this.getChargeId()
    }
  }
  //判断是否展示子账户选择弹窗
  getSubAccount = async (limit)=>{
    this.props.dispatch({
      type: 'dropshippersetting/getChildAccount',
      payload:{
        callback: d =>{
          if(d && d.code === 2000){
            let usableAccount = d.data?.content.filter(item=>{
              return item.status !== 2
            })
           this.setState({
             childAccount:usableAccount
           })
      if (usableAccount.length - 1 > limit && limit !== 0) {
        this.setState({
          accountChoice: true,
          accountquantity:limit
        });
      }else if(limit === 0){
        this.setAccountNouse()
      }
          }
        }
      }
  })
  }

  setAccountNouse = async ()=>{
    try {
      const res = await Put('/uaa/api/1.0/user/child_account/shopify/status',{
        data:{
          user_status:2,
          target_user:[],
          invited_user: []
          
        }
      },true)
    } catch (error) {
      console.log('fetch err',error)
    }
  }

  // 更新shopify授权
  goShopify = (shop_name, Boolean) => {
    this.props.dispatch({
      type: 'dropshippersetting/updateShopfiy',
      payload: {
        data: {
          shop_name: shop_name.slice(0, shop_name.indexOf('.'))
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            if (Boolean && d.msg == 'bind store quota err') {
               //  跳转订阅 && 弹窗拦截
              //  window.EVENT.emit(GuildeAction.TurnPlan_Pop, `k-${Date.now()}`, true);
                self.location.href = '/app/pricing?select=1';
            }

            window.location.href = d.data.url;
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };

  onchoice = e => {
    const { value } = e.target;
    const { stores } = this.state;
    const choice = stores.filter(item => item.id === value);
    const choiceLabel = choice[0]?.domain;
    this.setState({
      choiceLabel,
      buttonDisabled: false,
      value
    });
  };

  confirm = () => {
    window.dataLayer.push({
      event: 'subscriptionCustomEvent',
      type: 'activate',
      position: 'defaultBillingStore'
    });
    this.props.dispatch({
      type: 'guidance/modificationShop',
      payload: {
        data: {
          store_id: this.state.value
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.props.dispatch({
              type: 'guidance/getPlan',
              payload: {
                callback: d => {
                  if (d.code == 2000) {
                    localStorage.setItem('payment', JSON.stringify(d.data));
                    location.reload();
                  }
                }
              }
            });
          }
        }
      }
    });
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    const { stores } = this.state;
    const oldChoice = stores.filter(item => item.label === true);
    const oldValue = oldChoice[0]?.id;
    this.setState({
      visible: false,
      value: oldValue,
      buttonDisabled: true
    });
  };

  Activate = () => {
    this.setState({
      visible: true
    });
  };

  dispatchs = (shopvalue, woovalue) => {
    let val;
    if (shopvalue.length > 0) {
      this.KeepTheStore(shopvalue);
      this.props.dispatch({
        type: 'guidance/postGuidance',
        payload: {
          data: {
            status: 11000000
          }
        }
      });
    } else {
      localStorage.removeItem('keyStatus');
      this.KeepTheStore(woovalue);
      this.props.dispatch({
        type: 'guidance/postGuidance',
        payload: {
          data: {
            status: 11100000
          },
          callback: d => {
            if (d.code === 2000 || d.code === 2010) {
              // 跳woo
              window.location.href = goToWoo
            }
          }
        }
      });
    }
  };

  KeepTheStore = val => {
    this.props.dispatch({
      type: 'guidance/KeepTheStore',
      payload: {
        data: {
          stores_id: val
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              multipleChoice: false
            });
            window.location.href = '/app/'; // 首页
          }
        }
      }
    });
  };

  // eslint-disable-next-line
  StoreManagement(stores, val, operation, number, Boolean, Inactivated) {
    return (
      <div className={styles.belowKI}>
        <div className={styles.thicku}>{val}</div>

        <div className={styles.storeList}>
          {/* <p>
            <span className={styles.storeListTitle}>
              {intl.get('setting.account.store_name')}
            </span>
            <span className={styles.storeListTitle}>
              {intl.get('setting.account.status')}
            </span>
          </p> */}
          {stores && stores.length == 0 ? (
            <p className={styles.noData}>{intl.get('import_list.no_store')}</p>
          ) : (
            stores &&
            stores.map((item, index) => {
              return (
                <p key={index}>
                  <span>
                    {item.status == 1 ? (
                      item.store_name
                    ) : (
                      <div className={styles.red}>
                        {item.store_name}
                        <Tooltip
                          title={intl.get(
                            'setting.account.shopify_stores_re_authorized'
                          )}
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </div>
                    )}
                    {/* number为1的情况 */}
                    {
                      number == '1' ? <>
                        <div className={item.status != 1 ? styles.errprT : styles.sucessT}>
                          {item.status != 1 ? `Not connected` : intl.get('setting.account.connected')}
                        </div>
                      </> : null
                    }
                    {
                      number == '2' ? <>
                        <div className={item.status != 1 ? styles.errprTX : styles.sucessTX}>
                          {item.status != 1 ? `Not connected` : intl.get('setting.account.connected')}
                        </div>
                      </> : null
                    }

                  </span>

                  {/* <span>
                    {item.status != 1 ? (
                      <>
                        <a
                          className={styles.updateShopifyStore}
                          role="button"
                          tab-index="0"
                          onClick={() =>
                            this.goShopify(item.store_name, Boolean)
                          }
                        >
                          {intl.get('setting.account.click_to_renew')}
                        </a>
                        <Tooltip
                          title={intl.get('setting.account.shopify_stores_tip')}
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </>
                    ) : (
                      intl.get('setting.account.connected')
                    )}
                  </span> */}
                  <span>
                    {!item.disabled ? (
                      <>
                        {/* number为1的情况 */}
                        { number == '1' ?
                          <>
                            {/*  断开的时候才显示重连接 */}
                            {
                              item.status != '1' ? <a onClick={() =>
                                this.goShopify(item.store_name, Boolean)
                              }>
                                <i class="material-icons notranslate">sync</i>
                              </a> : null
                            }
                            <a
                              disabled={item.disabled}
                              className={styles.updateShopifyStore}
                              onClick={() => this.operate(item, number)}
                              role="button"
                              tab-index="0"
                            >
                              <i class="material-icons notranslate">delete</i>
                            </a>
                          </>
                        : <>
                            {
                              item.status != '1' ? <a onClick={() =>
                                this.goShopify(item.store_name, Boolean)
                              }>
                                <i class="material-icons notranslate">sync</i>
                              </a> : null
                            }
                            <a
                              disabled={item.disabled}
                              className={styles.updateShopifyStore}
                              onClick={() => this.operate(item, number)}
                              role="button"
                              tab-index="0"
                            >
                              <i class="material-icons notranslate">add</i>
                            </a>
                        </>
                        }

                      </>
                    ) : (
                      <a disabled className={styles.updateShopifyStore}>
                        {Inactivated}
                      </a>
                    )}
                  </span>
                </p>
              );
            })
          )}
        </div>
      </div>
    );
  }

  operate = (item, number) => {
    if (number == '1') {
      window.dataLayer.push({
        event: 'subscriptionCustomEvent',
        type: 'disconnect'
      });

      this.setState({
        disconnectShopifyStoreId: item.store_id
      });
    } else if (number == '2') {
      window.dataLayer.push({
        event: 'subscriptionCustomEvent',
        type: 'activate',
        position: 'storeManagement'
      });

      const { activate } = this.state;
      const val = [];
      activate &&
        activate.map(item => {
          val.push(item.store_id);
        });
      val.push(item.store_id);
      this.props.dispatch({
        type: 'guidance/KeepTheStore',
        payload: {
          data: {
            stores_id: val
          },
          callback: d => {
            if (d.code == 2000 || d.code == 2010) {
              location.replace(location);
            }
          }
        }
      });
    }
  };

  // 隐藏卸载shopify店铺弹窗
  handleUnDisconnect = () => {
    this.setState({
      disconnectShopifyStoreId: undefined
    });
  };

  // 卸载shopify店铺成功回调
  handleDisconnectCallback = () => {
    this.setState({
      disconnectShopifyStoreId: undefined
      // showDisconnectCallback: true
    });
  };

  handleSubscribe = () => {
    this.props.dispatch({
      type: 'guidance/reorder',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            const url = d.data.confirm_url;
            if (url) {
              window.open(url);
            }
          }
        }
      }
    });
  };
  //保存所选子账号
  saveAccounts= async (status_1,status_3)=>{
    try {
      const res = await Put('/uaa/api/1.0/user/child_account/shopify/status',{
        data:{
          user_status:2,
          target_user:status_1,
          invited_user: status_3
        }
      },true)
      if(res && res.code === 2010){
        if(this.state.multipleChoice){
          this.setState({
            accountChoice:false
          })
        }else{
          this.setState({
            accountChoice:false
          })
          window.location.href = '/app/'; // 首页
        }

      }
    } catch (error) {
      console.log('fetch err',error)
    }
  }

  handleLinkMoreStores = async () => {
      window.dataLayer.push({
        event: 'subscriptionCustomEvent',
        type: 'LinkToMoreStore'
      });
      const res = await Get('/auth/shopify/bind_quota',{})
      if(res.code == 2000 || res.code == 2010){
        if(res.msg == "bind store quota err"){
         localStorage.setItem('passivity', 'true');
         //  跳转订阅 && 弹窗拦截
         CommonDataCollect({
          action: 'more store-top',
          event_type:'overlimit_pop'
        });
        //  window.EVENT.emit(GuildeAction.TurnPlan_Pop, `more store&${Date.now()}`, true);

        this.setState({
          isModalVisiable: true
        })
         return
        }
        window.EVENT.emit(LinkStoreAction.REFRESH_PAGE,true)
        window.open("https://apps.shopify.com/dsers","_blank");
      }
  }

  getChargeId = async () => {
    const res = await Get('/charge/charge_id',{})
    this.setState({
      charge_id:res.data.charge_id
    })
  }

  closeDetail = () => {
    this.setState({
      isModalVisiable:false
    })
  }

  render() {
    const {
      setMealName,
      stores,
      value,
      choiceLabel,
      visible,
      woo_stores,
      status,
      buttonDisabled,
      type,
      quantity,
      showRefreshMask,
      multipleChoice,
      activate,
      nonactivated,
      mistakeText,
      mistakeDomain,
      pageTable,
      disconnectShopifyStoreId,
      showDisconnectCallback,
      accountChoice,
      charge_id
    } = this.state;

    const { paymentPlan } = this.props;

    if (!this.props.userInfo) {
      return (
        <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
      );
    }

    let howToUseSubscriptionLink =
      'https://help.dsers.com/change-subscription-plan-for-shopify/';
    if (getLanguage() === 'fr-FR') {
      howToUseSubscriptionLink =
        'https://help.dsers.com/change-subscription-plan-for-shopify/';
    }
    console.log(paymentPlan);
    return (
      <FixedHeaderLayout>
         <ResetPasswordModal />
        {pageTable ? (
          <div className={styles.shade}>
            <Spin size="large" />
          </div>
        ) : (
          ''
        )}

        <div className={styles.center}>
          <div className={styles.thick}> {intl.get('combo.headline')}</div>
          <div className={styles.rimC}>{intl.getHTML('combo.explain')}</div>
          <div className={styles.errorTip}>
            <span className={styles.icon}><i class="material-icons">error_outline</i></span>
            {paymentPlan && paymentPlan.status === 3 && charge_id ? <span className={styles.tit}>{intl.getHTML('pricing.subscription_network_tip',{jumpUrl:`/app/reminder?charge_id=${charge_id}`})}</span> : <span className={styles.tit}>{intl.get('pricing.subscription_network_tip_2')}</span>}
          </div>
          {paymentPlan &&
          paymentPlan.period === 2 &&
          new Date() > new Date(paymentPlan.trial_ends_on) &&
          (paymentPlan.type === 2 || paymentPlan.type === 3) ? (
            <div className={styles.errorTip}>
              <span className={styles.icon}><i class="material-icons">error_outline</i></span>
              <span className={styles.tit}>{intl.getHTML('pricing.change_annual_plan_notion')}</span>
            </div>
          ) : null}
          <div className={styles.belowK}>
            <div className={styles.thick}>
              <span>{intl.get('combo.change')}</span>
            </div>
            <div className={styles.list}>
              <i class="material-icons notranslate">monetization_on</i>
              <div className={styles.rimH}>
                <div className={styles.tit}>{intl.get('combo.changEcard')}</div>
                <div className={styles.setMealName}>{setMealName}</div>
              </div>
              <div className={styles.button}>
                <Button
                  data="dx"
                  onClick={() => {
                    window.dataLayer.push({
                      event: 'subscriptionCustomEvent',
                      type: 'changePlan'
                    });
                    window.location.href = '/app/pricing?select=1';
                  }}
                  type="primary"
                >
                  {intl.get('combo.changEcardBu')}
                </Button>
                {paymentPlan.status === 3 ? (
                  <Button
                    onClick={this.handleSubscribe}
                    style={{ marginLeft: 15 }}
                    type="primary"
                  >
                    {intl.get('pricing.Subscribe')}
                  </Button>
                ) : null}
              </div>
            </div>
            <div className={styles.concat}>
            </div>
          </div>

          {paymentPlan.status === 3 ? (
            <div className={styles.errorTip}>
              <span className={styles.icon}><i class="material-icons">error_outline</i></span>
              <span className={styles.tit}>{intl.getHTML('pricing.subscription_expired')}</span>
            </div>
          ) : null}
          {mistakeText && type !== 1 ? (
            <div className={styles.Alert}>
              <div className={styles.errorTip}>
                <span className={styles.icon}><i class="material-icons">error_outline</i></span>
                <span className={styles.tit}>{
                  mistakeDomain
                    ? intl.get('pricing.errText', { mistakeDomain })
                    : intl.get('pricing.errTextTwo')
                }</span>
              </div>
            </div>
          ) : null}

          <div className={styles.belowK}>
            <div className={styles.thick}>
              <span>{intl.get('combo.paymentcard')}</span>
            </div>
            <div className={styles.paddingBox}>
              <div className={styles.below}>
                {intl.get('combo.paymentcardEcard')}
              </div>
              <Radio.Group
                className={styles.RadioGroup}
                onChange={this.onchoice}
                value={value}
              >
                {stores &&
                  stores.map(item => {
                    return (
                      <span key={item.id} className={styles.radioBox}>
                        <Radio
                          className={styles.Radio}
                          disabled={item.status == 2}
                          value={item.id}
                          name={item.domain}
                        >
                          {/* {item.domain} */}
                          {`${item.shop_name}.myshopify.com`}
                          {item.label ? (
                            <div className={styles.defaultStore}>
                                {intl.get('pricing.billing')}
                              {/* <Tooltip
                                title={intl.get('pricing.help')}
                                arrowPointAtCenter="false"
                                placement="topLeft"
                              >
                                <Icon
                                  className={styles.help}
                                  type="question-circle"
                                />
                              </Tooltip> */}
                            </div>
                          ) : (
                            ''
                          )}{' '}
                        </Radio>
                      </span>
                    );
                  })}
              </Radio.Group>
              <div className={styles.rightButton}>
                <Button
                  data="dx"
                  className={styles.ButtonOne}
                  type="primary"
                  onClick={this.handleLinkMoreStores}
                >
                  {intl.get('guidance.link_to_more_stores')}
                </Button>
                <Button
                  className={styles.ButtonTwo}
                  disabled={buttonDisabled}
                  onClick={this.Activate}
                  type="primary"
                >
                  {intl.get('combo.paymentcardBu')}
                </Button>
              </div>
            </div>
          </div>
          {
            this.state.isModalVisiable ? <CAM002 camType={'MultiStore'} closeDetail={this.closeDetail} /> : null 
            }
          <Modal
            title={intl.get('setting.account.change_account_title')}
            visible={visible}
            okText={intl.get('public.confirm')}
            cancelText={intl.get('public.cancel')}
            onOk={this.confirm}
            className={styles.confirmBox}
            onCancel={this.handleCancel}
            centered
            width={560}
          >
            <p>
              {intl.get('setting.account.change_account_content', {
                choiceLabel
              })}
            </p>
          </Modal>

          <div className={styles.belowK}>
            <div className={styles.thick}>
              <span>{intl.get('pricing.StoreManagement')}</span>
            </div>
            <div className={styles.paddingBox}>
              <div className={styles.below}>{intl.get('pricing.StoreManagementText')}</div>

              {this.StoreManagement(
                activate,
                intl.get('pricing.ActiveStore'),
                intl.get('setting.account.disconnect'),
                '1',
                true
              )}
              {this.StoreManagement(
                nonactivated,
                intl.get('pricing.InactiveStore'),
                intl.get('pricing.Activate'),
                '2',
                false,
                intl.get('pricing.Inactivated')
              )}
            </div>
          </div>
          <ProductLimit></ProductLimit>
        </div>
        {/* multipleChoice */}
        {multipleChoice ? (
          <Demotion dispatchs={this.dispatchs} quantity={quantity} />
        ) : (
          ''
        )}

        {/* 账号超限弹窗 */}
        {accountChoice ? (<DemotionAccount saveAccounts={this.saveAccounts} accountQuantity={this.state.accountquantity} childAccount={this.state.childAccount}/>) : ('')}
         
        <RefreshMask visible={showRefreshMask} />
        <WhyDisconnect
          shopifyStoreId={disconnectShopifyStoreId}
          unDisconnect={this.handleUnDisconnect}
          disconnectCallback={this.handleDisconnectCallback}
        />
        <DisconnectCallback visible={showDisconnectCallback} />
        <OblerloGuide />
        <TmallStartImport/>
      </FixedHeaderLayout>
    );
  }
}
